<template>
    <div class="not-available-page"
        ref="notAvailablePageRef"
    >
        <div v-if="browserType?.isSafari"
            class="content-container"
        >
            <div class="error-container">
                <div class="text-container">
                    <p class="error-title">
                        {{ i18n.safariCannotConnect[languageCode] }}
                    </p>
                </div>
                <div class="text-container">
                    <p class="error-message">
                        {{ i18n.safariCannotOpen[languageCode] + host + i18n.safariBecause[languageCode] + hostname + i18n.safariMarksAndDot[languageCode] }}
                    </p>
                </div>
            </div>
        </div>
        <div v-else
            class="interstitial-wrapper"
            :class="{
                ['are-details-open']: areDetailsVisible
            }"
        >
            <div class="main-content">
                <img class="icon icon-generic not-found-icon" :src="'page-not-found.png'">
                <img class="icon icon-generic not-found-icon-light" :src="'page-not-found-light.png'">
                <div v-if="error === 'ERR_CONNECTION_REFUSED'"
                    class="main-message"
                >
                    <h1>{{ i18n.headline[languageCode] }}</h1>
                    <p class="summary"><strong>{{ hostname }}</strong>{{ i18n.refusedToConnect[languageCode] }}</p>
                    <div class="options">
                        <p>{{ i18n.try[languageCode] }}</p>
                        <ul>
                            <li>{{ i18n.checkingTheConnection[languageCode] }}</li>
                            <li class="link"
                                @click="toggleDetails"
                            >
                                {{ i18n.checkingTheProxyAndFirewall[languageCode] }}
                            </li>
                        </ul>
                    </div>
                    <div class="error-code">{{ i18n.errorCodeConnectionRefused[languageCode] }}</div>
                </div>
                <div v-if="error === 'DNS_PROBE_FINISHED_NXDOMAIN'"
                    class="main-message"
                >
                    <h1>{{ i18n.headline[languageCode] }}</h1>
                    <p class="summary">{{ i18n.summary[languageCode] + hostname + '.' }}</p>
                    <div class="error-code">{{ i18n.errorCodeFinishedDomain[languageCode] }}</div>
                </div>
            </div>
            <div class="nav-wrapper">
                <div class="control-buttons">
                    <button
                        @click="reload"
                        >
                        {{ i18n.reload[languageCode] }}
                    </button>
                    <button
                        class="secondary"
                        v-if="error === 'ERR_CONNECTION_REFUSED'"
                        @click="toggleDetails"
                    >
                        {{ areDetailsVisible ? i18n.hideDetails[languageCode] : i18n.details[languageCode] }}
                    </button>
                </div>
            </div>
            <div class="details"
                v-if="areDetailsVisible"
            >
                <div class="suggestions">
                    <div class="suggestion-header">
                        {{ i18n.suggestion_header_1[languageCode] }}
                    </div>
                    <div class="suggestion-body">
                        {{ i18n.suggestion_body_1[languageCode] }}
                    </div>
                </div>
                <div class="suggestions">
                    <div class="suggestion-header">
                        {{ i18n.suggestion_header_2[languageCode] }}
                    </div>
                    <div class="suggestion-body">
                        {{ i18n.suggestion_body_2[languageCode] }}
                    </div>
                </div>
                <div class="suggestions">
                    <div class="suggestion-header">
                        {{ i18n.suggestion_header_3[languageCode] }}
                    </div>
                    <div class="suggestion-body">
                        {{ i18n.suggestion_body_3[languageCode] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBrowser } from '@/utils/browser.utils'
import html2canvas from 'html2canvas';

export default {
    name: 'NotAvailable',
    components: {
    },
    data() {
        return {
            browserType: null,
            hostname: 'maer.org',
            host: 'www.maer.org',
            languageCode: 'en',
            error: 'ERR_CONNECTION_REFUSED',
            areDetailsVisible: false,
            i18n: {
                headline: {
                    en: 'This site can\'t be reached'
                },
                summary: {
                    en: 'Check if there is a typo in www.'
                },
                refusedToConnect: {
                    en: ' refused to connect.'
                },
                errorCodeFinishedDomain: {
                    en: 'DNS_PROBE_FINISHED_NXDOMAIN'
                },
                errorCodeConnectionRefused: {
                    en: 'ERR_CONNECTION_REFUSED'
                },
                try: {
                    en: 'Try:'
                },
                checkingTheConnection: {
                    en: 'Checking the connection'
                },
                checkingTheProxyAndFirewall: {
                    en: 'Checking the proxy and the firewall'
                },
                details: {
                    en: 'Details'
                },
                hideDetails: {
                    en: 'Hide details'
                },
                reload: {
                    en: 'Reload'
                },
                suggestion_header_1: {
                    en: 'Check your  Internet connection'
                },
                suggestion_body_1: {
                    en: 'Check any cables and reboot any routers, modems or other network devices you may be using.'
                },
                suggestion_header_2: {
                    en: 'Allow Chrome to access the network in your firewall or antivirus settings.'
                },
                suggestion_body_2: {
                    en: 'It it is already listed as a program allowed to access the network, try removing it from the list and adding it again.'
                },
                suggestion_header_3: {
                    en: 'If you use a proxy server...'
                },
                suggestion_body_3: {
                    en: 'Check your proxy settings or contact your network administrator to make sure that the proxy server is working. If you don\'t believe you should be using a proxy server: Go to Applications > System Preferences > Network > Advanced > Proxies and deselect any proxies that have been selected.'
                },
                safariCannotConnect: {
                    en: 'Safari Can\'t Connect to the Server'
                },
                safariCannotOpen: {
                    en: 'Safari can\'t open the page "'
                },
                safariBecause: {
                    en: '" because Safari can\'t connect to the server "'
                },
                safariMarksAndDot: {
                    en: '".'
                }
            },
            screenCanvasId: 'screenCanvas',
            resetCanvasId: 'resetCanvas',
            isRendering: false,
            readCanvas: null,
            initialFlickerDelay: 1000,
            continuousFlickerDelay: 3800
        }
	},
    mounted() {
        this.readBrowser()
        if (window.location.hostname) {
            this.hostname = window.location.hostname
            this.host = window.location.host
        }
        setTimeout(() => {
            this.initCanvi()
            setTimeout(() => {
                this.flicker()
            }, this.initialFlickerDelay)
        }, 10)
        window.addEventListener('resize', this.renderCanvasDelayed)
    },
    unmounted() {
        window.removeEventListener('resize', this.renderCanvasDelayed)
    },
    computed: {
    },
    methods: {
        readBrowser() {
            this.browserType = getBrowser()
        },
        reload() {
            window.location.reload()
        },
        toggleDetails() {
            this.areDetailsVisible = !this.areDetailsVisible
            this.rerenderHtmlCanvas()
        },
        initCanvi() {
            const insertionTarget = this.$refs.notAvailablePageRef
            const screenCanvasId = this.screenCanvasId
            const resetCanvasId = this.resetCanvasId
            html2canvas(document.body, { logging: false }).then(function(canvas) {
                canvas.id = screenCanvasId
                canvas.style.position = 'absolute';
                canvas.style.top = '0';
                canvas.style.left = '0';
                canvas.style.pointerEvents = 'none';
                canvas.style.width = '100%'
                canvas.style.height = '100%'
                insertionTarget.appendChild(canvas);
                const resetCanvas = canvas.cloneNode(true)
                const resetContext = resetCanvas.getContext('2d')
                resetCanvas.id = resetCanvasId
                resetCanvas.style.opacity = 0
                resetContext.drawImage(canvas, 0, 0)
                insertionTarget.appendChild(resetCanvas);
            });
        },
        rerenderHtmlCanvas() {
            if (!this.isRendering) {
                const that = this
                const screenCanvas = document.getElementById(this.screenCanvasId)
                const resetCanvas = document.getElementById(this.resetCanvasId)
                this.isRendering = true
                screenCanvas.style.display = 'none';
                setTimeout(() => {
                    html2canvas(document.body, { logging: false }).then(function(readCanvas) {
                        const screenContext = screenCanvas.getContext('2d')
                        screenCanvas.width = readCanvas.width
                        screenCanvas.height = readCanvas.height
                        screenContext.drawImage(readCanvas, 0, 0)
                        screenCanvas.style.display = 'block';
                        const resetContext = resetCanvas.getContext('2d')
                        resetCanvas.width = readCanvas.width
                        resetCanvas.height = readCanvas.height
                        resetContext.drawImage(readCanvas, 0, 0)
                    });
                    that.isRendering = false
                }, 10)
            }
        },
        resetHtmlCanvas() {
            const screenCanvas = document.getElementById(this.screenCanvasId)
            const screenContext = screenCanvas.getContext('2d')
            const resetCanvas = document.getElementById(this.resetCanvasId)
            const resetContext = resetCanvas.getContext('2d')
            const resetImageData = resetContext.getImageData(0, 0, resetCanvas.width, resetCanvas.height)
            screenContext.putImageData(resetImageData, 0, 0)
        },
        renderCanvasDelayed() {
            this.renderTimeout = setTimeout(() => {
                this.rerenderHtmlCanvas()
            }, 100)
        },
        flicker() {
            const screenCanvas = document.getElementById(this.screenCanvasId)
            const screenContext = screenCanvas.getContext('2d')
            const canvasWidth = screenCanvas.width
            const canvasHeight = screenCanvas.height
            const shiftedLineSetsCounts = 3
            const minShiftedLinesPerSet = 20
            const maxShiftedLinesPerSet = 200
            const shiftRangePerLine = 5
            const shiftDirectionThreshold = 0.51
            const colorShiftThreshold = 50
            const extraShiftDelay = 100
            const shiftedLineSets = [...Array(shiftedLineSetsCounts).keys()].map(() => {
                const randomStartRow = Math.floor(Math.random() * canvasHeight)
                const randomSetHeight = Math.floor(Math.random() * (maxShiftedLinesPerSet - minShiftedLinesPerSet))
                return {
                    startRow: randomStartRow - randomSetHeight,
                    setHeight: randomSetHeight
                }
            })
            const shiftedLineSetsExtra = shiftedLineSets.map(lineSet => {
                const direction = Math.random() < shiftDirectionThreshold ? 1 : -1
                const extraShift = direction * Math.floor(Math.random() * lineSet.setHeight)
                return {
                    ...lineSet,
                    startRow: lineSet.startRow + extraShift
                }
            })
            const doShifting = function(lineSet) {
                let recentShift = 0
                let recentShiftDirection = 1
                for (let i = 0; i < lineSet.setHeight; i++) {
                    recentShiftDirection = Math.random() < shiftDirectionThreshold ? 1 : -1
                    recentShift += recentShiftDirection * Math.floor(Math.random() * shiftRangePerLine)
                    const row = lineSet.startRow + i 
                    const lineImageData = screenContext.getImageData(0, row, canvasWidth, 1)
                    const redOffset = Math.floor(Math.random() * 255)
                    const greenOffset = Math.floor(Math.random() * 255)
                    const blueOffset = Math.floor(Math.random() * 255)
                    for (let p = 0; p < lineImageData.data.length; p += 4) {
                        if (lineImageData.data[p + 0] > colorShiftThreshold) {
                            lineImageData.data[p + 0] += redOffset
                            lineImageData.data[p + 1] += greenOffset
                            lineImageData.data[p + 2] += blueOffset
                        }
                    }
                    screenContext.putImageData(lineImageData, recentShift, row)
                }
            }
            shiftedLineSets.forEach(doShifting)
            setTimeout(() => {
                shiftedLineSetsExtra.forEach(doShifting)
            }, extraShiftDelay)
            setTimeout(() => {
                this.renderTimeout = setTimeout(() => {
                    this.resetHtmlCanvas()
                    this.renderTimeout = setTimeout(() => {
                        this.flicker()
                    }, Math.floor(Math.random() * this.continuousFlickerDelay) + 100)
                }, 100)
            }, extraShiftDelay + 10)
        }
    }
}
</script>

<style scoped lang="scss">
    .not-available-page {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: white;
        color: rgb(95, 99, 104);
        z-index: $z-index-start-lock;
        opacity: 1;
        transition: opacity 1s;
        font-size: 15px;
        line-height: 24px;

        &.unlocked {
            pointer-events: none;
            opacity: 0;
        }

        .content-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .error-container {
                min-width: 320px;
                max-width: 580px;
                text-align: center;
                font-family: system-ui, -apple-system;

                .text-container {
                    .error-title {
                        font-size: 28px;
                        line-height: 34px;
                        font-weight: 700;
                        color: rgb(133, 133, 133);
                        margin-block-end: 0;
                    }

                    .error-message {
                        font-size: 13px;
                        line-height: 18px;
                        padding: 0px 24px;
                        color: rgb(110, 110, 110);
                    }
                }
            }
        }

        .not-found-icon {
            display: block;
        }

        .not-found-icon-light {
            display: none;
        }

        .interstitial-wrapper {
            margin: 14vh auto 0;
            max-width: 600px;
            font-family: system-ui, sans-serif;

            .main-content {
                .icon {
                    height: 72px;
                    margin: 0 0 40px;
                    width: 72px;
                }
    
                .main-message {
                    h1 {
                        margin-top: 0;
                        font-size: 1.6em;
                        font-weight: 500;
                        line-height: 1.25em;
                        margin-bottom: 16px;
                        color: rgb(32, 33, 36);
                    }
    
                    .summary {
                        color: rgb(95,99, 104);
                        font-size: 1em;
                    }
    
                    .options {
                        color: rgb(95,99, 104);
                        font-size: 1em;

                        p {
                            margin-block-start: 0;
                            margin-block-end: 0;
                        }
                        
                        ul {
                            list-style-type: unset;
                            margin-block-start: 0;
                            margin-inline-start: 0;
                            padding-inline-start: 40px;
    
                            li {
                                &:hover {
                                    cursor: default;
                                    color: rgb(95,99, 104);
                                }

                                &.link {
                                    cursor: pointer;
                                    color: rgb(26, 115, 232);
                                }
                            }
                        }
                    }
    
                    .error-code {
                        margin-top: 12px;
                        color: rgb(95,99, 104);
                        font-size: 0.8em;
                    }
                }
            }

            .nav-wrapper {
                margin-top: 51px;

                .control-buttons {
                    width: 100%;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;

                    button {
                        border: 0;
                        border-radius: 20px;
                        box-sizing: border-box;
                        cursor: pointer;
                        float: right;
                        font-size: .875em;
                        line-height: normal;
                        margin: 0;
                        padding: 8px 16px;
                        transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1);
                        user-select: none;
                        background: rgb(26, 115, 232);
                        color: #fff;

                        &.secondary {
                            background-color: #fff;
                            color: rgb(95, 99, 104);
                            border: 1px solid rgb(154, 160, 166);

                            &:hover {
                                background: rgb(248, 249, 250);
                                border-color: rgb(128, 134, 139);
                            }
                        }
                    }
                }
            }

            .details {
                margin-bottom: 50px;

                .suggestions {
                    margin-top: 18px;
                    color: rgb(95,99, 104);
    
                    .suggestion-header {
                        font-weight: bold;
                        margin-bottom: 4px;                
                    }
                }
            }
        }
    }


    @media (prefers-color-scheme: dark) {
        .not-available-page {
            background-color: rgb(32, 33, 36);
            color: rgb(154, 160, 166);

            .not-found-icon {
                display: none;
                filter: invert(1);
            }

            .not-found-icon-light {
                display: block;
            }

            .interstitial-wrapper {
                .main-content {
                    .icon {
                        height: 72px;
                        margin: 0 0 40px;
                        width: 72px;
                    }
        
                    .main-message {
                        h1 {
                            color: rgb(154, 160, 166);
                        }
        
                        .summary {
                            color: rgb(154, 160, 166);
                        }
        
                        .options {
                            color: rgb(154, 160, 166);

                            ul {
                                li {
                                    &:hover {
                                        color: rgb(154, 160, 166);
                                    }
    
                                    &.link {
                                        color: rgb(138, 180, 248);
                                    }
                                }
                            }
                        }
        
                        .error-code {
                            color: rgb(154, 160, 166);
                        }
                    }
                }

                .nav-wrapper {
                    .control-buttons {
                        button {
                            background: rgb(138, 180, 248);
                            color: rgb(32, 33, 36);
    
                            &.secondary {
                                background-color: rgb(32, 33, 36);
                                color: rgb(138, 180, 248);
                                border: 1px solid rgb(95, 99, 104);
    
                                &:hover {
                                    background: rgb(48, 51, 57);
                                    border-color: rgb(128, 134, 139);
                                }
                            }
                        }
                    }
                }
            }

            .suggestions {
                color: rgb(154, 160, 166);

                .suggestion-header {
                    color: rgb(154, 160, 166);
                }
                .suggestion-body {
                    color: #777;
                }
            }
        }
    }

    @media (max-width: 700px) {
        .not-available-page {
            .interstitial-wrapper {
                padding: 0 10%;
            }
        }
    }

    @media (max-width: 420px) {
        .not-available-page {
            .interstitial-wrapper {
                .nav-wrapper {
                    position: fixed;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    padding-bottom: 16px;
                    
                    .control-buttons {
                        box-sizing: border-box;
                        width: 100%;
                        display: block;
                        padding-inline-start: 24px;
                        padding-inline-end: 24px;

                        button {
                            width: 100%;
                            padding: 16px 24px;
                            margin-top: 6px;
                            margin-bottom: 6px;
                            border-radius: 20px;

                            &.secondary {
                                border: none;
                            }
                        }
                    }
                }
                    
                .details {
                    display: none;
                }

                &.are-details-open {
                    .main-content {
                        display: none;
                    }

                    .details {
                        display: block;
                    }
                }
            }
        }
    }
</style>
